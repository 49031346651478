import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import { Optional } from '@silae/helpers';

export function useDateFormatter(): {
	asDurationTime: (from: DateTime, to: DateTime) => Optional<string>;
	asDateTimeShort: (time: DateTime) => Optional<string>;
	asDateShort: (time: DateTime) => Optional<string>;
	asTimeShort: (time: DateTime) => Optional<string>;
} {
	const { t } = useI18n();

	const asDateTimeShort = (datetime: DateTime) =>
		datetime?.isValid
			? t('common.format.date-time-short', {
					date: datetime.toLocaleString(DateTime.DATE_SHORT),
					time: datetime.toLocaleString(DateTime.TIME_SIMPLE)
				})
			: undefined;

	const asDurationTime = (from: DateTime, to: DateTime) =>
		from?.isValid && to?.isValid
			? t('common.format.duration-time-short', {
					from: from.toLocaleString(DateTime.TIME_SIMPLE),
					to: to.toLocaleString(DateTime.TIME_SIMPLE)
				})
			: undefined;

	const asTimeShort = (datetime: DateTime) => (datetime?.isValid ? datetime.toLocaleString(DateTime.TIME_SIMPLE) : undefined);

	const asDateShort = (datetime: DateTime) => (datetime?.isValid ? datetime.toLocaleString(DateTime.DATE_SHORT) : undefined);

	return {
		asDurationTime,
		asDateTimeShort,
		asTimeShort,
		asDateShort
	};
}
