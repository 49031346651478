import { storeToRefs } from 'pinia';
import { Observable, of, switchMap } from 'rxjs';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { Optional } from '@silae/helpers';
import {
	CONGES_PAYES_CODE,
	LeaveDaysCreationRequest,
	LeaveDaysDTO,
	LeaveDaysUpdateRequest,
	createLeaveDays$,
	createLeaveDaysAsAdmin$,
	createLeaveDaysAsManager$,
	fetchSplittingDaysConfiguration$,
	modifyLeaveDays$,
	modifyLeaveDaysAsAdmin$,
	modifyLeaveDaysAsManager$
} from '~/api';
import { useSelfManager } from '~/composables/roles.composables.ts';
import { useCompanySelectionStore } from '~/stores/company-selection.store.ts';
import { useRolesStore } from '~/stores/roles.store.ts';

import { useSplitDaysComposables } from './split-days.composables.ts';

export function useSplitDaysConfirmationModal(leaveDaysTypeCode: ComputedRef<string>): {
	handleSplitDays(leaveDaysAction$: Observable<void>): Observable<void>;
} {
	const { isEmployee } = storeToRefs(useRolesStore());
	const { employeeCompanyId } = storeToRefs(useCompanySelectionStore());
	const { showDialogSplitDaysToTriggerAction } = useSplitDaysComposables();

	const needsToControlSplitDays = computed(() => isEmployee.value && leaveDaysTypeCode.value === CONGES_PAYES_CODE);

	const needsToShowSplitDaysModal = (): Observable<boolean> => {
		if (needsToControlSplitDays.value && employeeCompanyId.value != undefined) {
			return fetchSplittingDaysConfiguration$(employeeCompanyId.value);
		}

		return of(false);
	};

	const handleSplitDays = (leaveDaysAction$: Observable<void>): Observable<void> => {
		return needsToShowSplitDaysModal().pipe(
			switchMap(needsToShow => {
				if (needsToShow) {
					return showDialogSplitDaysToTriggerAction(leaveDaysAction$);
				}
				return leaveDaysAction$;
			})
		);
	};

	return {
		handleSplitDays
	};
}

export function useLeaveDaysOperations(leaveDays?: Ref<Optional<LeaveDaysDTO>>): {
	createLeaveDays$: (request: LeaveDaysCreationRequest) => Observable<void>;
	modifyLeaveDays$: (request: LeaveDaysUpdateRequest) => Observable<void>;
} {
	const leaveDaysTypeCode = computed(() => leaveDays?.value?.typeCode ?? '');
	const { handleSplitDays } = useSplitDaysConfirmationModal(leaveDaysTypeCode);
	const { isManager, isAdmin } = storeToRefs(useRolesStore());

	const companyId: Ref<Optional<number>> = ref();
	const { isSelfManager } = useSelfManager(companyId, leaveDays);

	const _createLeaveDays$: (request: LeaveDaysCreationRequest) => Observable<void> = (request: LeaveDaysCreationRequest) => {
		companyId.value = request.companyId;

		let request$: Observable<void>;
		if (isAdmin.value) {
			request$ = createLeaveDaysAsAdmin$(request);
		} else if (isManager.value || isSelfManager.value) {
			request$ = createLeaveDaysAsManager$(request);
		} else {
			request$ = createLeaveDays$(request);
		}

		return handleSplitDays(request$);
	};

	const _modifyLeaveDays$: (request: LeaveDaysUpdateRequest) => Observable<void> = (request: LeaveDaysUpdateRequest) => {
		companyId.value = request.companyId;

		let request$: Observable<void>;
		if (isAdmin.value) {
			request$ = modifyLeaveDaysAsAdmin$(request);
		} else if (isManager.value || isSelfManager.value) {
			request$ = modifyLeaveDaysAsManager$(request);
		} else {
			request$ = modifyLeaveDays$(request);
		}

		return handleSplitDays(request$);
	};

	return {
		createLeaveDays$: _createLeaveDays$,
		modifyLeaveDays$: _modifyLeaveDays$
	};
}
