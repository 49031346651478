import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { useFeatures } from '~/composables';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout';

export const AdministrativeDocumentsRoute: RouteRecordRaw = {
	name: RouteKeys.DOCUMENTS_ADMINISTRATIVE,
	path: 'administrative-documents',
	component: () => import('./administrative-documents/administrative-documents-list.vue'),
	meta: {
		isForbidden: () =>
			computed(() => {
				const { isManager, isAdmin } = storeToRefs(useRolesStore());
				return isAdmin.value || isManager.value;
			})
	}
};

export const PayslipsRoute: RouteRecordRaw = {
	name: RouteKeys.DOCUMENTS_PAYSLIPS,
	path: 'payslips',
	component: () => import('./payslips/payslips-route.vue'),
	meta: {
		isForbidden: () =>
			computed(() => {
				const { isEmployee, isAdmin } = storeToRefs(useRolesStore());
				return (
					!(isEmployee.value && useFeatures().hasPayslipsFeature.value) &&
					!(isAdmin.value && useFeatures().hasPayslipFullCompanyViewFeature.value)
				);
			})
	}
};

export const SalaryTransferRoute: RouteRecordRaw = {
	name: RouteKeys.DOCUMENTS_SALARY_TRANSFER,
	path: 'salary-transfer',
	component: () => import('./salary-transfer/salary-transfer.vue'),
	meta: {
		isForbidden: () => computed(() => !useFeatures().hasSalaryTransferFeature.value)
	}
};

export const DocumentsRoute: RouteRecordRaw = {
	name: RouteKeys.DOCUMENTS,
	path: '/documents',
	components: {
		default: () => import('./documents.vue'),
		...defaultLayoutComponents
	},
	children: [PayslipsRoute, AdministrativeDocumentsRoute, SalaryTransferRoute],
	redirect: () =>
		useFeatures().hasPayslipsFeature.value || useFeatures().hasPayslipFullCompanyViewFeature.value
			? PayslipsRoute
			: AdministrativeDocumentsRoute,
	meta: {
		isForbidden: () => {
			const { isManager, isAdmin } = storeToRefs(useRolesStore());
			const { hasSelfOnboardingFeature, hasPayslipFullCompanyViewFeature, hasSalaryTransferFeature } = useFeatures();
			return computed(
				() =>
					Devices().isMobile ||
					isManager.value ||
					hasSelfOnboardingFeature.value ||
					(isAdmin.value && !(hasPayslipFullCompanyViewFeature.value || hasSalaryTransferFeature.value))
			);
		},
		label: 'GED.navigation',
		icon: () => $i('folder'),
		isNavEntry: true,
		order: 4,
		requiresAuth: true
	}
};
