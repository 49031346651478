import { storeToRefs } from 'pinia';
import { ComputedRef, computed } from 'vue';

import { useObservable } from '@silae/composables';
import { Optional } from '@silae/helpers';
import { ISODateString, LeaveDaysCountersDTO } from '~/api';
import { useLeaveDaysCountersStore, useRolesStore } from '~/stores';

import { useSelectedEmployeeCompanyWatcher } from './company.composables';
import { useFeatures } from './features.composables';

export function usePersonalLeaveDaysCounters(): {
	counters: ComputedRef<Array<LeaveDaysCountersDTO>>;
	at: ComputedRef<Optional<ISODateString>>;
	isLeaveDaysCountersVisible: ComputedRef<boolean>;
	loading: ComputedRef<boolean>;
	fetchCounters: (companyId: number, options?: { at?: ISODateString; invalidate?: boolean }) => void;
} {
	const { trigger } = useObservable();
	const { fetchCounters$, invalidateCounters } = useLeaveDaysCountersStore();
	const { loading } = useSelectedEmployeeCompanyWatcher(fetchCounters$);
	const { hasCountersFeature } = useFeatures();

	const { at, counters } = storeToRefs(useLeaveDaysCountersStore());

	const hasCountersData = computed(() => (counters.value?.length ?? 0) > 0);
	const isLeaveDaysCountersVisible = computed(() => hasCountersFeature.value && hasCountersData.value);
	const { isEmployee } = storeToRefs(useRolesStore());

	const fetchCounters = (companyId: number, options?: { at?: ISODateString; invalidate?: boolean }) => {
		if (options?.invalidate) invalidateCounters();
		if (isEmployee.value) trigger(fetchCounters$(companyId, options?.at));
	};

	return {
		at,
		counters,
		isLeaveDaysCountersVisible,
		loading,
		fetchCounters
	};
}
