import { RouteRecordRaw } from 'vue-router';

import { AccountRoute } from '~/pages/account/account.route';
import { ConfigurationRoute } from '~/pages/admin/configuration/configuration.route';
import { EmployeeDetailsRoute } from '~/pages/admin/employees/employee-details/employee-details.route';
import { AdminEmployeeManagementRoute } from '~/pages/admin/employees/employees.route';
import { MedicalCheckupsRoute } from '~/pages/admin/medical-checkups/medical-checkups.route';
import { PayrollRoute } from '~/pages/admin/payroll/payroll.route';
import { SignDocumentsRoute } from '~/pages/admin/sign-document/sign-documents.route';
import { AgendaRoute } from '~/pages/agenda/agenda.route';
import { AutomaticEarnedWageAccessesRoute } from '~/pages/automatic-earned-wage-accesses/automatic-earned-wage-accesses.route';
import { DocumentsRoute } from '~/pages/documents/documents.route';
import { HomeRoute } from '~/pages/home/home.route';
import { LeavesAndMobilitiesRoute } from '~/pages/leaves-and-mobilities/leaves-and-mobilities.route';
import { MeetingsRoute } from '~/pages/meetings/meetings.route';
import { DownloadFileRoute, PublicDownloadExpiredRoute } from '~/pages/share-document/download-file.route';
import { UserSignRoute } from '~/pages/sign-document-public-page/public-sign.route';
import { ConnectionRoute, ImpersonationRoute, SignInDenarioRoute, SignInRoute } from '~/pages/sign-in/sign-in.route';
import { UpdatePasswordRoute } from '~/pages/update-password/update-password.route';
import { RouteKeys } from '~/router/routes.domain';

const FallbackRoute: RouteRecordRaw = {
	name: RouteKeys.NOT_FOUND,
	path: '/:pathMatch(.*)*',
	redirect: HomeRoute,
	meta: {
		public: true
	}
};

export const routes = [
	AccountRoute,
	AdminEmployeeManagementRoute,
	AgendaRoute,
	AutomaticEarnedWageAccessesRoute,
	ConfigurationRoute,
	ConnectionRoute,
	DownloadFileRoute,
	EmployeeDetailsRoute,
	DocumentsRoute,
	HomeRoute,
	ImpersonationRoute,
	LeavesAndMobilitiesRoute,
	MedicalCheckupsRoute,
	MeetingsRoute,
	PayrollRoute,
	PublicDownloadExpiredRoute,
	SignDocumentsRoute,
	SignInDenarioRoute,
	SignInRoute,
	UpdatePasswordRoute,
	UserSignRoute,
	FallbackRoute
];
