import { AxiosResponse } from 'axios';
import { Observable, filter, of, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComputedRef, Ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	FileFormat,
	FilesHintGetter,
	FilesInputHooks,
	InvalidFileFormatErrorGetter,
	MaxFileSizeErrorGetter,
	MaxFilesErrorGetter,
	MimeType,
	SimpleFile,
	useFilesInput
} from '@silae/components';
import { useObservable } from '@silae/composables';
import { Optional } from '@silae/helpers';

import { FileDraftUploadResponse, uploadFileDraft$ } from '../api';

const PREVIEWABLE_MIME_TYPES = [MimeType.PDF, MimeType.PNG, MimeType.JPEG];

export function useFilesDraftUpload(
	companyId?: ComputedRef<Optional<number>>,
	hooks?: FilesInputHooks<FileDraftUploadResponse>,
	fetchDocument$?: (companyId: number, fileId: string) => Observable<AxiosResponse<string>>
): {
	addExistingFiles: (existingFiles: SimpleFile[]) => void;
	canPreviewFile: (file: SimpleFile) => boolean;
	files: Ref<Array<SimpleFile>>;
	filesHint: FilesHintGetter;
	invalidFormatError: InvalidFileFormatErrorGetter;
	label: string;
	maxFilesError: MaxFilesErrorGetter;
	maxFileSizeError: MaxFileSizeErrorGetter;
	previewFile: (file: SimpleFile) => void;
	removeFile: (file: string | SimpleFile) => void;
} {
	const { trigger } = useObservable();
	const { t } = useI18n();
	const label = t('common.buttons.add-documents');
	const maxFileSizeError = (maxSize: number) => t('form.error.maximum_size', { size: maxSize, unit: t('common.file.size.megabyte') });
	const maxFilesError = (maxFiles: number) => t('common.rules.max_files_exceeded', { n: maxFiles });
	const invalidFormatError = (formats: Array<FileFormat>) => t('form.error.required_formats', { formats: formats.join(', ') });
	const filesHint = (formats: Array<FileFormat>, maxFilesSizeMo: number) => {
		const formatsHint = formats.join(', ');
		const maxSizeHint = maxFileSizeError(maxFilesSizeMo);
		return [formatsHint, maxSizeHint].filter(Boolean).join(' — ');
	};

	const upload$: (file: SimpleFile) => Observable<FileDraftUploadResponse> = (file: SimpleFile) => {
		const formData = new FormData();
		formData.append('file', file.file);
		return of(companyId?.value).pipe(
			filter((id: Optional<number>) => id != undefined),
			switchMap((id: number) => uploadFileDraft$(id, formData))
		);
	};

	const { files, removeFile, addExistingFiles } = useFilesInput<FileDraftUploadResponse>(upload$, hooks);

	function canPreviewFile(file: SimpleFile): boolean {
		return PREVIEWABLE_MIME_TYPES.includes(file.file?.type as MimeType);
	}

	const previewFile = (file: SimpleFile) => {
		const companyIdTmp = companyId?.value;
		if (!file.file?.size && fetchDocument$ && companyIdTmp) {
			trigger(
				fetchDocument$(companyIdTmp, file.id).pipe(
					map(bytesToFile),
					switchMap((content: Optional<File>) => {
						if (content) {
							const index = files.value.findIndex(it => it.id === file.id);
							const updatedFile = { ...file, file: content };
							if (index > -1) {
								files.value = [...files.value.slice(0, index), updatedFile, ...files.value.slice(index + 1)];
							}
							return of(updatedFile);
						}
						return of(file);
					}),
					tap((file: SimpleFile) => {
						if (file.file) {
							const fileURL = URL.createObjectURL(file.file);
							window.open(fileURL);
						}
					})
				)
			);
		} else {
			const fileURL = URL.createObjectURL(file.file);
			window.open(fileURL);
		}
	};

	const bytesToFile = (response: AxiosResponse<string>): Optional<File> =>
		new File([new Blob([response.data])], response.headers['x-filename'], { type: response.headers['content-type'] });

	return {
		addExistingFiles,
		canPreviewFile,
		files,
		filesHint,
		invalidFormatError,
		label,
		maxFilesError,
		maxFileSizeError,
		previewFile,
		removeFile
	};
}
