import { Optional } from '@silae/helpers';
import { ISODateString } from '~/api';

export type RemoteWorkRequestForm = {
	companyId: Optional<number>;
	employeeId: Optional<number>;
	datesField?: Array<ISODateString>;
	commentsField?: string;
};

export const REMOTE_WORK_TYPE_CODE = '#TTP';
export const REMOTE_WORK_TYPE_LABEL = 'JourTélétravailPonctuel';
export const REMOTE_WORK_DEFAULT_LIMIT_OF_DAYS = 3;
