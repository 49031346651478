import { watchImmediate } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { Observable } from 'rxjs';
import { ComputedRef } from 'vue';

import { useObservable } from '@silae/composables';
import { useCompanySelectionStore, useRolesStore } from '~/stores';

// when this function is called more than once, callback is called multiple times with watchImmediate
export function useSelectedEmployeeCompanyWatcher<T = any>(cb: (companyId: number) => Observable<T>): { loading: ComputedRef<boolean> } {
	const { employeeCompanyId } = storeToRefs(useCompanySelectionStore());
	const { isEmployee } = storeToRefs(useRolesStore());
	const { loading, trigger } = useObservable();

	watchImmediate(
		() => ({
			id: employeeCompanyId.value,
			isEmployee: isEmployee.value
		}),
		({ id, isEmployee }) => {
			if (id != null && isEmployee) {
				const callback$ = cb(id);
				trigger(callback$);
			}
		}
	);

	return { loading };
}

// when this function is called more than once, callback is called multiple times with watchImmediate
export function useSelectedManagerCompaniesWatcher<T = any>(
	cb: (companyIds: Array<number>) => Observable<T>
): { loading: ComputedRef<boolean> } {
	const { managerCompaniesIds } = storeToRefs(useCompanySelectionStore());
	const { loading, trigger } = useObservable();
	const { isManager } = storeToRefs(useRolesStore());

	watchImmediate(
		() => ({
			selectedManagerCompaniesIds: managerCompaniesIds.value,
			isManager: isManager.value
		}),
		({ isManager, selectedManagerCompaniesIds }) => {
			if (isManager) {
				const callback$ = cb(selectedManagerCompaniesIds);
				trigger(callback$);
			}
		}
	);

	return { loading };
}

// when this function is called more than once, callback is called multiple times with watchImmediate
export function useSelectedAdminCompanyWatcher<T = any>(cb: (companyId: number) => Observable<T>): { loading: ComputedRef<boolean> } {
	const { adminCompanyId } = storeToRefs(useCompanySelectionStore());
	const { loading, trigger } = useObservable();
	const { isAdmin } = storeToRefs(useRolesStore());

	watchImmediate(
		() => ({
			selectedAdminCompanyId: adminCompanyId.value,
			isAdmin: isAdmin.value
		}),
		({ isAdmin, selectedAdminCompanyId }) => {
			if (isAdmin && selectedAdminCompanyId != null) {
				const callback$ = cb(selectedAdminCompanyId);
				trigger(callback$);
			}
		}
	);

	return { loading };
}
