import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { Devices } from '../../plugins';
import { defaultLayoutComponents } from '../layout';
import { useRemoteWorkConfiguration } from './mobilities';

export const RemoteWorkRequestsRoute: RouteRecordRaw = {
	name: RouteKeys.LEAVES_AND_MOBILITIES_REMOTE_WORK,
	path: 'remote-work',
	component: () => import('./mobilities/remote-work.vue'),
	meta: {
		isForbidden: () => {
			const { canRequestRemoteWork } = useRemoteWorkConfiguration();
			return computed(() => !canRequestRemoteWork.value);
		}
	}
};

export const LeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.LEAVES_AND_MOBILITIES_LEAVE_DAYS,
	path: 'leave-days',
	component: () => import('./leave-days/leave-days.vue')
};

export const LeavesAndMobilitiesRoute: RouteRecordRaw = {
	name: RouteKeys.LEAVES_AND_MOBILITIES,
	path: '/leaves-and-mobilities',
	components: {
		default: () => import('./leaves-and-mobilities.vue'),
		...defaultLayoutComponents
	},
	children: [LeaveDaysRoute, RemoteWorkRequestsRoute],
	redirect: () => LeaveDaysRoute,
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const { hasSelfOnboardingFeature } = useFeatures();
			return computed(
				() =>
					(isAdmin.value && !useFeatures().hasLeaveDayAdminFullFeature.value) ||
					hasSelfOnboardingFeature.value ||
					Devices().isMobile
			);
		},
		label: 'requests.navigation',
		icon: '$list',
		isNavEntry: true,
		order: 3,
		requiresAuth: true
	}
};
