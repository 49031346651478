import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';

const PayslipsValidationPendingRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_PAYSLIPS_VALIDATION_PENDING,
	path: '',
	component: () => import('./components/payslips-validation-pending.vue')
};

const PayslipsValidationCompleteRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_PAYSLIPS_VALIDATION_COMPLETE,
	path: 'complete',
	component: () => import('./components/payslips-validation-complete.vue')
};

export const PayslipsValidationRoute: RouteRecordRaw = {
	name: 'payslips-validation',
	path: 'payslips-validation',
	components: {
		default: () => import('./payslips-validation.vue'),
		...defaultLayoutComponents
	},
	children: [PayslipsValidationPendingRoute, PayslipsValidationCompleteRoute],
	redirect: PayslipsValidationPendingRoute,
	meta: {
		isForbidden: () => computed(() => !useFeatures().hasPayslipValidationFeature.value),
		label: 'admin.navigation.payslips-validation',
		isSubEntry: true,
		order: 31,
		requiresAuth: true
	}
};
