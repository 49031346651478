import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { Optional } from '@silae/helpers';
import {
	AxiosApiError,
	BonusRequest,
	EmployeeID,
	EmployeeVariableBonusDetailsAndDefinitionsDTO,
	EmployeeVariableBonusDetailsDTO,
	ISODateString,
	JobID,
	VariableBonusDefinitionDTO,
	VariableBonusDetailsDTO,
	VariableBonusUpsertRequest,
	VariableBonusUpsertResponse,
	VariableElementCode,
	fetchEmployeeBonusDetailsAndDefinitions$,
	updateEmployeeBonus$
} from '~/api';
// do not shorten import to prevent circular dep
import { useTracking } from '~/composables/tracking.composables';
// do not shorten import to prevent circular dep
import { VariableElementType } from '~/pages/admin/payroll/variable-elements/variable-elements.domain';
import { Stopwatch } from '~/utils';

import { useEmployeesStore } from '../employees';
import { Clearable } from '../store.domain';
import { populateValidationElementsCache, sortEmployeeVariableElementsDetailsByLastName } from './variable-elements.store.utils';

export type VariableBonusStore = Clearable & {
	bonusDefinitions: ComputedRef<Array<VariableBonusDefinitionDTO>>;
	bonusValidationDefinitions: ComputedRef<Array<VariableBonusDefinitionDTO>>;
	employeesBonusesDetails: Ref<Array<EmployeeVariableBonusDetailsDTO>>;
	employeesBonusesValidationDetails: Ref<Array<EmployeeVariableBonusDetailsDTO>>;
	fetchVariableBonusDetailsAndDefinition$: (
		companyId: number,
		request: BonusRequest,
		invalidateCache?: boolean
	) => Observable<Optional<EmployeeVariableBonusDetailsAndDefinitionsDTO>>;
	fetchVariableBonusValidationDetailsAndDefinition$: (
		companyId: number,
		request: BonusRequest,
		invalidateCache?: boolean
	) => Observable<EmployeeVariableBonusDetailsAndDefinitionsDTO>;
	updateVariableBonusDetails$: (
		companyId: number,
		period: ISODateString,
		employeeId: EmployeeID,
		jobId: JobID,
		request: VariableBonusUpsertRequest
	) => Observable<VariableBonusUpsertResponse>;
};

export const useVariableBonusStore = defineStore<'variable-bonus', VariableBonusStore>('variable-bonus', () => {
	const { cache$: bonusCache$, clearCache: clearBonusCache } = useHttpCache<string, EmployeeVariableBonusDetailsAndDefinitionsDTO>();

	const { track } = useTracking();

	const { employeesByCompany } = storeToRefs(useEmployeesStore());

	const _bonusDefinitionsCache: Ref<Map<VariableElementCode, VariableBonusDefinitionDTO>> = ref(new Map());
	const _bonusEmployeeIDsCache: Ref<Map<EmployeeID, boolean>> = ref(new Map());
	const _employeesBonusesDetailsCache: Ref<Array<EmployeeVariableBonusDetailsDTO>> = ref([]);

	const _bonusValidationDefinitions: Ref<Array<VariableBonusDefinitionDTO>> = ref([]);
	const _employeesBonusesValidationDetails: Ref<Array<EmployeeVariableBonusDetailsDTO>> = ref([]);

	const clear = () => {
		_bonusDefinitionsCache.value = new Map();
		_bonusEmployeeIDsCache.value = new Map();
		_employeesBonusesDetailsCache.value = [];
		_bonusValidationDefinitions.value = [];
		_employeesBonusesValidationDetails.value = [];
		clearBonusCache();
	};

	function fetchVariableBonusValidationDetailsAndDefinition$(
		companyId: number,
		request: BonusRequest
	): Observable<EmployeeVariableBonusDetailsAndDefinitionsDTO> {
		request.doPreComputedSearch = true;
		return fetchEmployeeBonusDetailsAndDefinitions$(companyId, request).pipe(
			tap(dnd => {
				_bonusValidationDefinitions.value = dnd.definitions;
				_employeesBonusesValidationDetails.value = dnd.employeeDetails;
			})
		);
	}

	function fetchVariableBonusDetailsAndDefinition$(
		companyId: number,
		request: BonusRequest,
		invalidateCache?: boolean
	): Observable<Optional<EmployeeVariableBonusDetailsAndDefinitionsDTO>> {
		// TODO implement cache invalidation only for provided employee list ID
		if (invalidateCache) {
			_bonusEmployeeIDsCache.value.clear();
			_bonusDefinitionsCache.value.clear();
			_employeesBonusesDetailsCache.value.length = 0;
			clearBonusCache();
		}

		// make sure pre computation is off
		request.doPreComputedSearch = false;

		// fetch bonus for employees we haven't fetched yet
		const employeesIds = request.employeesIds.filter(id => !_bonusEmployeeIDsCache.value.has(id));
		const cheapRequest = { ...request, employeesIds };
		const cacheKey = JSON.stringify(cheapRequest);
		const stopwatch = new Stopwatch();
		const fetch$ = bonusCache$(
			cacheKey,
			fetchEmployeeBonusDetailsAndDefinitions$(companyId, cheapRequest).pipe(
				tap(dnd => {
					track(
						'Variable Elements Fetched',
						{ companyId, stopwatch },
						{ employees_count: employeesIds.length, variable_element_type: VariableElementType.BONUSES }
					);
					populateValidationElementsCache(
						companyId,
						employeesIds,
						dnd,
						_bonusDefinitionsCache.value,
						_bonusEmployeeIDsCache.value,
						_employeesBonusesDetailsCache.value,
						employeesByCompany.value
					);
				})
			)
		);

		return employeesIds.length > 0 ? fetch$ : of(undefined);
	}

	// update bonus value and refresh cache
	function updateVariableBonusDetails$(
		companyId: number,
		period: ISODateString,
		employeeId: number,
		jobId: number,
		request: VariableBonusUpsertRequest
	): Observable<VariableBonusUpsertResponse> {
		return updateEmployeeBonus$(companyId, period, employeeId, jobId, request).pipe(
			catchError((err: AxiosApiError<{ value: string }>) => {
				if (err.response?.data?.context?.value != null) {
					// we can have an error when local data has diverged from server data
					// update local data cache with the one contained in the error from server if any
					const backendValue = err.response.data.context?.value;
					_employeesBonusesDetailsCache.value = updateEmployeeDetailsCache(
						_employeesBonusesDetailsCache.value,
						employeeId,
						jobId,
						request,
						backendValue
					);
				}
				return throwError(() => err);
			}),
			tap(
				response =>
					(_employeesBonusesDetailsCache.value = updateEmployeeDetailsCache(
						_employeesBonusesDetailsCache.value,
						employeeId,
						jobId,
						request,
						response.value
					))
			)
		);
	}
	return {
		bonusDefinitions: computed(() => Array.from(_bonusDefinitionsCache.value.values())),
		bonusValidationDefinitions: computed(() => _bonusValidationDefinitions.value),
		clear,
		employeesBonusesDetails: computed(() =>
			_employeesBonusesDetailsCache.value.toSorted(sortEmployeeVariableElementsDetailsByLastName)
		),
		employeesBonusesValidationDetails: computed(() => _employeesBonusesValidationDetails.value),
		fetchVariableBonusDetailsAndDefinition$,
		fetchVariableBonusValidationDetailsAndDefinition$,
		updateVariableBonusDetails$
	};
});

// TODO use a map for local cache for better performances
function updateEmployeeDetailsCache(
	employeesBonusesDetails: Array<EmployeeVariableBonusDetailsDTO>,
	employeeId: number,
	jobId: number,
	request: VariableBonusUpsertRequest,
	backendValue: string
): Array<EmployeeVariableBonusDetailsDTO> {
	return employeesBonusesDetails.reduce((updatedCache, employeeVariableDetails) => {
		// unaffected employee or job
		if (employeeVariableDetails.employeeId !== employeeId || employeeVariableDetails.jobId !== jobId) {
			return [...updatedCache, employeeVariableDetails];
		}

		const value = backendValue ?? request.newValue;
		let updatedDetails: Array<VariableBonusDetailsDTO>;
		// quick return in case of a bonus creation
		if (request.previousValue == null) {
			updatedDetails = [...employeeVariableDetails.details, { code: request.code, value }];
		} else {
			updatedDetails = employeeVariableDetails.details.reduce((updatedDetails, bonus) => {
				if (bonus.code !== request.code) {
					return [...updatedDetails, bonus];
				}
				return [...updatedDetails, { ...bonus, value }];
			}, [] as Array<VariableBonusDetailsDTO>);
		}

		const updateEmployeeVariableDetails = { ...employeeVariableDetails, details: updatedDetails };
		return [...updatedCache, updateEmployeeVariableDetails];
	}, [] as Array<EmployeeVariableBonusDetailsDTO>);
}

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useVariableBonusStore, import.meta.hot));
