import { NotificationsOptions, useNotification } from '@kyvg/vue3-notification';

import { UiLevel } from '@silae/helpers';

import { Message, MessageData, MessageType } from './message.domain';

/**
 *  if you need to tune notifications see:
 *  https://kyvg.github.io/vue3-notification/
 *  */
const { notify } = useNotification();
const defaultWarningToastDuration = 3000;

function message(msg: string | Message, type: MessageType, level: UiLevel = UiLevel.INFO, options?: NotificationsOptions): void {
	const text = typeof msg === 'string' ? msg : msg.text;
	const title = typeof msg === 'string' ? undefined : msg.title;

	function dataType(type: MessageType): Partial<MessageData> {
		return type === MessageType.DIALOG ? { isDialog: true } : { isToast: true };
	}

	const data: MessageData = typeof msg === 'string' ? dataType(type) : { ...msg, ...dataType(type) };

	notify({
		title,
		text,
		data,
		type: level,
		...options
	});
}

const notifyToastInfo = (msg: string | Message) => message(msg, MessageType.TOAST);
const notifyToastSuccess = (msg: string | Message) => message(msg, MessageType.TOAST, UiLevel.SUCCESS);
const notifyToastWarning = (msg: string | Message, duration?: number) =>
	message(msg, MessageType.TOAST, UiLevel.WARNING, { duration: duration ?? defaultWarningToastDuration });
const notifyToastError = (msg: string | Message) =>
	message(msg, MessageType.TOAST, UiLevel.ERROR, { duration: 7000, ignoreDuplicates: true });

const notifyDialogInfo = (msg: string | Message) => message(msg, MessageType.DIALOG);
const notifyDialogSuccess = (msg: string | Message) => message(msg, MessageType.DIALOG, UiLevel.SUCCESS, { duration: -1 });
const notifyDialogWarning = (msg: string | Message) => message(msg, MessageType.DIALOG, UiLevel.WARNING, { duration: -1 });
const notifyDialogError = (msg: string | Message) => message(msg, MessageType.DIALOG, UiLevel.ERROR, { duration: -1 });

export {
	notifyDialogError,
	notifyDialogInfo,
	notifyDialogSuccess,
	notifyDialogWarning,
	notifyToastError,
	notifyToastInfo,
	notifyToastSuccess,
	notifyToastWarning
};
