import { Observable } from 'rxjs';

import { COMPANY_BASE_URL } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import {
	AccessModificationRequest,
	ActivationError,
	EmailType,
	EmployeeLoginDTO,
	InvitationRequest,
	UpdateEmployeeRequest,
	UpdatePreferredEmailRequest
} from './logins.dto';

const EMPLOYEES_API = `employees`;
const CONFIGURATION_ACCESS_API = `configuration/access`;

export function getAllEmployees$(companyId: number): Observable<Array<EmployeeLoginDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${EMPLOYEES_API}/details`);
}

export function updateEmployees$(
	companyId: number,
	employeeId: number,
	request: UpdateEmployeeRequest
): Observable<Array<EmployeeLoginDTO>> {
	const { http } = useBackendHttpService();
	return http.put(`${COMPANY_BASE_URL}/${companyId}/${EMPLOYEES_API}/${employeeId}`, request);
}

export function manageEmployeeAccess$(companyId: number, request: AccessModificationRequest): Observable<ActivationError> {
	const { http } = useBackendHttpService();
	return http.patch(`${COMPANY_BASE_URL}/${companyId}/${CONFIGURATION_ACCESS_API}`, request);
}

export function sendInvitation$(companyId: number, employeeId: number): Observable<void> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${CONFIGURATION_ACCESS_API}/invitations/${EMPLOYEES_API}/${employeeId}`);
}

export function sendInvitations$(companyId: number, request: InvitationRequest): Observable<void> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${CONFIGURATION_ACCESS_API}/invitations/${EMPLOYEES_API}`, request);
}

export function updatePreferredEmails$(companyId: number, request: UpdatePreferredEmailRequest): Observable<void> {
	const { http } = useBackendHttpService();
	return http.patch(`${COMPANY_BASE_URL}/${companyId}/${CONFIGURATION_ACCESS_API}/email-preference/${EMPLOYEES_API}`, request);
}

export function getPreferredEmail$(companyId: number): Observable<EmailType> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${CONFIGURATION_ACCESS_API}/email-preference/${EMPLOYEES_API}`);
}
