import {
	EmployeeID,
	EmployeeVariableElementsDetailsAndDefinitions,
	EmployeeVariableElementsDetailsDTO,
	VariableElementCode,
	VariableElementDefinitionDTO
} from '~/api';
import { Employee } from '~/domain';

export function populateValidationElementsCache(
	companyId: number,
	employeesIds: Array<EmployeeID>,
	dnd: EmployeeVariableElementsDetailsAndDefinitions,
	definitionsCache: Map<VariableElementCode, VariableElementDefinitionDTO>,
	employeeIDsCache: Map<EmployeeID, boolean>,
	detailsCache: Array<EmployeeVariableElementsDetailsDTO>,
	employeesByCompany: Map<number, Array<Employee>>
) {
	(dnd?.definitions ?? []).forEach(def => {
		if (!definitionsCache.has(def.code)) {
			definitionsCache.set(def.code, def);
		}
	});

	// update details cache with fetched data
	(dnd?.employeeDetails ?? []).forEach(employeeDetails => {
		employeeIDsCache.set(employeeDetails.employeeId, true);
		detailsCache.push(employeeDetails);
	});

	// it might happen that backend fails to retrieve some data for a user
	// cache we've already fetched it to avoid some later errors
	const noDataIds = employeesIds.filter(it => dnd.employeeDetails.findIndex(employeeDetails => it === employeeDetails.employeeId) < 0);
	noDataIds.forEach(id => {
		employeeIDsCache.set(id, true);
		const employeeInformation = employeesByCompany?.get(companyId)?.find(em => em.employeeId === id);
		detailsCache.push({
			...employeeInformation,
			companyId,
			jobId: undefined,
			jobName: undefined,
			details: []
		} as unknown as EmployeeVariableElementsDetailsDTO);
	});
}

export function sortEmployeeVariableElementsDetailsByLastName(
	a: EmployeeVariableElementsDetailsDTO,
	b: EmployeeVariableElementsDetailsDTO
): number {
	if (a.lastName === b.lastName) {
		return 0;
	}

	if (a.lastName == null && b.lastName != null) {
		return 1;
	}

	if (a.lastName != null && b.lastName == null) {
		return -1;
	}

	return a.lastName!.localeCompare(b.lastName!);
}
