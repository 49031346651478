import { LocationQuery } from 'vue-router';

import { Optional } from '@silae/helpers';

export function stripLocationQueryParams(query: LocationQuery, paramsToStrip: Array<string>): Optional<LocationQuery> {
	if (query == null) {
		return;
	}
	const strippedQuery = { ...query };
	paramsToStrip.forEach(param => {
		delete strippedQuery[param];
	});
	return strippedQuery;
}
