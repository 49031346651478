import { watchImmediate } from '@vueuse/core';
import isEmpty from 'lodash/isEmpty';
import { storeToRefs } from 'pinia';
import { ComputedRef, computed } from 'vue';

import { useObservable } from '@silae/composables';
import { CompanyInformationDTO } from '~/api';
import { useCompanyInformationStore, useCompanySelectionStore, useRolesStore } from '~/stores';

export function useCompaniesInformation(): {
	informationPerCompany: ComputedRef<Map<number, CompanyInformationDTO>>;
} {
	const { trigger } = useObservable();
	const { employeeCompanyId, managerCompaniesIds, adminCompanyId } = storeToRefs(useCompanySelectionStore());
	const { isManager, isAdmin } = storeToRefs(useRolesStore());
	const informationStore = useCompanyInformationStore();
	const { informationPerEmployeeCompany, informationPerManagedCompany, informationPerAdminCompany } = storeToRefs(informationStore);

	const informationPerCompany = computed(() => {
		if (isAdmin.value) return informationPerAdminCompany.value;
		if (isManager.value) return informationPerManagedCompany.value;

		return informationPerEmployeeCompany.value;
	});

	// load selected companies information
	watchImmediate(employeeCompanyId, id => {
		if (id != undefined) {
			trigger(informationStore.getEmployeeCompanyInformation$(id));
		}
	});
	watchImmediate(managerCompaniesIds, ids => {
		if (!isEmpty(ids)) {
			trigger(informationStore.listManagedCompaniesInformation$(ids));
		}
	});

	watchImmediate(adminCompanyId, id => {
		if (id != undefined) {
			trigger(informationStore.listAdminCompaniesInformation$([id]));
		}
	});

	return { informationPerCompany };
}
