import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useHttpCache } from '@silae/composables';
import { Optional } from '@silae/helpers';
import { MedicalCheckupDTO, MedicalCheckupModuleStateDTO, listMedicalCheckups$, listServices$ } from '~/api';
import { useDateFormatter } from '~/composables/date-formatter.composables.ts';
import { PrettyMedicalCheckupDTO } from '~/domain';
import { prettyEmployeeName } from '~/utils';

import { Clearable } from '../store.domain.ts';

export type MedicalCheckupsStore = Clearable & {
	fetchMedicalCheckups: (companyId: number) => Observable<Array<MedicalCheckupDTO>>;
	fetchMedicalCheckupsForEmployee: (companyId: number, employeeId: number) => Observable<Array<MedicalCheckupDTO>>;
	fetchServices: (companyId: number, invalidate?: boolean) => Observable<Map<string, MedicalCheckupModuleStateDTO>>;
	medicalCheckups: ComputedRef<Array<PrettyMedicalCheckupDTO>>;
	medicalCheckupsForEmployee: ComputedRef<Array<PrettyMedicalCheckupDTO>>;
	services: ComputedRef<Map<string, MedicalCheckupModuleStateDTO>>;
};

export const useMedicalCheckupsStore = defineStore<'medical-checkups-store', MedicalCheckupsStore>('medical-checkups-store', () => {
	const _services: Ref<Map<string, MedicalCheckupModuleStateDTO>> = ref(new Map());
	const { cache$, clearCache } = useHttpCache<number, Map<string, MedicalCheckupModuleStateDTO>>();

	const { asTimeShort, asDurationTime, asDateShort } = useDateFormatter();
	const { t } = useI18n();

	const clear = () => {
		clearCache();
		medicalCheckupsClearCache();
		medicalCheckupsForEmployeeClearCache();
	};

	const fetchServices = (companyId: number, invalidate?: boolean): Observable<Map<string, MedicalCheckupModuleStateDTO>> => {
		if (invalidate) {
			clearCache();
		}

		return cache$(
			companyId,
			listServices$(companyId).pipe(
				map(serviceMapObject => {
					const mappedServices = new Map<string, MedicalCheckupModuleStateDTO>();
					// Somehow ... a map returned from the API is not a map but an Object !
					for (const [key, value] of Object.entries(serviceMapObject)) {
						mappedServices.set(key, {
							...value,
							id: key
						});
					}
					return mappedServices;
				})
			)
		).pipe(tap(services => (_services.value = services ?? new Map())));
	};

	const _medicalCheckups: Ref<Array<PrettyMedicalCheckupDTO>> = ref([]);
	const medicalCheckups: ComputedRef<Array<PrettyMedicalCheckupDTO>> = computed(() => _medicalCheckups.value);
	const { cache$: medicalCheckupsCache$, clearCache: medicalCheckupsClearCache } = useHttpCache<number, Array<PrettyMedicalCheckupDTO>>();
	const fetchMedicalCheckups = (companyId: number): Observable<Array<PrettyMedicalCheckupDTO>> => {
		return medicalCheckupsCache$(
			companyId,
			listMedicalCheckups$(companyId, true).pipe(map(medicalCheckups => medicalCheckups.map(convertToPrettyMedicalCheckup)))
		).pipe(tap(medicalCheckups => (_medicalCheckups.value = medicalCheckups)));
	};

	const _medicalCheckupsForEmployee: Ref<Array<PrettyMedicalCheckupDTO>> = ref([]);
	const medicalCheckupsForEmployee: ComputedRef<Array<PrettyMedicalCheckupDTO>> = computed(() => _medicalCheckupsForEmployee.value);
	const { cache$: medicalCheckupsCacheForEmployee$, clearCache: medicalCheckupsForEmployeeClearCache } = useHttpCache<
		string,
		Array<PrettyMedicalCheckupDTO>
	>();
	const fetchMedicalCheckupsForEmployee = (companyId: number, employeeId: number): Observable<Array<PrettyMedicalCheckupDTO>> => {
		return medicalCheckupsCacheForEmployee$(
			`${companyId}_${employeeId}`,
			listMedicalCheckups$(companyId, false, employeeId).pipe(
				map(medicalCheckups => {
					return medicalCheckups.map(medicalCheckup => {
						return convertToPrettyMedicalCheckup(medicalCheckup);
					});
				})
			)
		).pipe(
			tap(cachedResult => {
				_medicalCheckupsForEmployee.value = cachedResult;
			})
		);
	};

	function getFormattedDurationTime(medicalCheckup: MedicalCheckupDTO) {
		if (!medicalCheckup.date || !medicalCheckup.endTime) {
			return t(`common.fields.none-in-table`);
		}
		return (
			asDurationTime(
				DateTime.fromISO(medicalCheckup.date),
				DateTime.fromISO(medicalCheckup.date).set({
					hour: DateTime.fromFormat(medicalCheckup.endTime, 'HH:mm:ss').hour,
					minute: DateTime.fromFormat(medicalCheckup.endTime, 'HH:mm:ss').minute
				})
			) ?? t(`common.fields.none-in-table`)
		);
	}

	function getFormattedDate(dateField: Optional<string>) {
		return preventUndefinedWithPlaceholder(dateField, date => asDateShort(DateTime.fromISO(date)));
	}
	function getFormattedTime(dateField: Optional<string>) {
		return preventUndefinedWithPlaceholder(dateField, date => asTimeShort(DateTime.fromISO(date)));
	}

	function preventUndefinedWithPlaceholder(field: Optional<string>, valueSupplier: (safeField: string) => Optional<string> | string) {
		if (!field) {
			return t(`common.fields.none-in-table`);
		}
		return valueSupplier(field) ?? t(`common.fields.none-in-table`);
	}

	function convertToPrettyMedicalCheckup(medicalCheckup: MedicalCheckupDTO): PrettyMedicalCheckupDTO {
		return {
			...medicalCheckup,
			uniqueKey: `${medicalCheckup.employee.id}_${medicalCheckup.id}`,
			institutionLabel: medicalCheckup.institution?.name ?? t(`common.fields.none-in-table`),
			employeeFullName: medicalCheckup.employee
				? prettyEmployeeName(medicalCheckup.employee.firstName, medicalCheckup.employee.lastName)
				: t(`common.fields.none-in-table`),
			formattedDate: getFormattedDate(medicalCheckup.date),
			formattedDurationTime: getFormattedDurationTime(medicalCheckup),
			formattedConveningDate: getFormattedDate(medicalCheckup.conveningDate),
			formattedConveningTime: getFormattedTime(medicalCheckup.conveningDate),
			formattedNextCheckupDate: getFormattedDate(medicalCheckup.nextDate),
			medicalConclusionLabel: medicalCheckup.medicalConclusion
				? t(`medical-checkups.fields.medical-conclusion.${medicalCheckup.medicalConclusion.label}`)
				: t(`common.fields.none-in-table`),
			medicalNatureLabel: medicalCheckup.nature
				? t(`medical-checkups.fields.nature.${medicalCheckup.nature.label}`)
				: t(`common.fields.none-in-table`),
			medicalNatureDetails: medicalCheckup.nature
				? t(`medical-checkups.fields.nature.details.${medicalCheckup.nature.details}`)
				: t(`common.fields.none-in-table`),
			medicalCenterLabel: medicalCheckup.medicalCenter?.name ?? t(`common.fields.none-in-table`),
			medicalPractitionerTypeLabel: medicalCheckup.medicalPractitionerType
				? t(`medical-checkups.fields.practitioner-type.${medicalCheckup.medicalPractitionerType}`)
				: t(`common.fields.none-in-table`)
		};
	}

	return {
		clear,
		fetchMedicalCheckups,
		fetchMedicalCheckupsForEmployee,
		fetchServices,
		medicalCheckups,
		medicalCheckupsForEmployee,
		services: computed(() => _services.value)
	};
});
