import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import { Optional } from '@silae/helpers';
import { LeaveDaysDTO, LeaveDaysHistory, LeaveDaysState } from '~/api';
import { PrettyLeaveDays } from '~/domain';
import { ISODateAsLocalDate, prettyEmployeeName, splitHoursAndMinutes } from '~/utils';

export function useLeaveDays(): {
	getPrettyDate: (ISO: string) => Optional<string>;
	getPrettyLabel: (label: string | undefined) => string;
	asPrettyLeaveDays: (leaveDays: LeaveDaysDTO) => PrettyLeaveDays;
	getPrettyLeaveDaysPeriod: (leaveDays: LeaveDaysDTO) => string;
	getPrettyLeaveDaysDuration: (leaveDays: LeaveDaysDTO) => string;
	getPrettyLeaveDaysHistoryPeriod: (history: LeaveDaysHistory) => string;
	getPrettyLeaveDaysHistoryDuration: (history: LeaveDaysHistory) => string;
	findLastInHistory: (history: LeaveDaysHistory[], state: LeaveDaysState) => Optional<LeaveDaysHistory>;
} {
	const { t } = useI18n();
	// Must be in a composable because needs the composable useI18n
	const asPrettyLeaveDays = (leaveDays: LeaveDaysDTO): PrettyLeaveDays => {
		return {
			...leaveDays,
			selected: false,
			label: getPrettyLabel(leaveDays.label),
			employeeName: prettyEmployeeName(leaveDays.employeeFirstName, leaveDays.employeeLastName),
			prettyStart: getPrettyDate(leaveDays.start),
			prettyEnd: getPrettyDate(leaveDays.end),
			prettyDuration: getPrettyLeaveDaysDuration(leaveDays)
		};
	};

	const getPrettyLabel = (label: string | undefined): string => {
		return label ? label : t('common.fields.no_type');
	};

	const getPrettyDate = (ISO: string): Optional<string> => ISODateAsLocalDate(ISO, DateTime.DATE_SHORT);

	/**
	 * Ex: Journée, 0h45, ...
	 */
	const getPrettyLeaveDaysDuration = (leaveDays: LeaveDaysDTO): string => {
		if (leaveDays.isHalfDay) {
			if (leaveDays.halfDayOption) {
				return t(`common.fields.half_day.${leaveDays.halfDayOption}`);
			}
			return t('common.fields.half_day.label');
		}
		if (leaveDays.isHours) {
			const { hours, minutes } = splitHoursAndMinutes(leaveDays.hours);
			return t('common.fields.hours_minutes_value', { hours, minutes });
		}
		return t('common.fields.short_day');
	};

	/**
	 * Ex: du 13/02/2024 au 15/02/2024, le 17/02/2024 - 0h45, ...
	 */
	const getPrettyLeaveDaysPeriod = (leaveDays: LeaveDaysDTO): string => {
		if (leaveDays.isHalfDay) {
			if (leaveDays.halfDayOption) {
				return t(`common.fields.period.half_day.${leaveDays.halfDayOption}`, { date: getPrettyDate(leaveDays.start) });
			}
			return t('common.fields.period.half_day.not_specified', { date: getPrettyDate(leaveDays.start) });
		}
		if (leaveDays.isHours) {
			return t('common.fields.period.hours', {
				date: getPrettyDate(leaveDays.start),
				duration: getPrettyLeaveDaysDuration(leaveDays)
			});
		}

		const start = DateTime.fromISO(leaveDays.start);
		const end = DateTime.fromISO(leaveDays.end);
		const diffInDays = end.diff(start, 'days').days;
		if (diffInDays >= 1) {
			return t('common.fields.period.many_days', {
				dateStart: getPrettyDate(leaveDays.start),
				dateEnd: getPrettyDate(leaveDays.end)
			});
		}
		return t('common.fields.period.one_day', { date: getPrettyDate(leaveDays.start) });
	};

	/**
	 * Ex: Journée, 0h45, ...
	 */
	const getPrettyLeaveDaysHistoryDuration = (history: LeaveDaysHistory): string => {
		if (history.isHalfDay) {
			if (history.halfDayOption) {
				return t(`common.fields.half_day.${history.halfDayOption}`);
			}
			return t('common.fields.half_day.label');
		}
		if (history.isHours) {
			const split = history.hours.split(':');
			const { hours, minutes } = { hours: split[0], minutes: split[1] };
			return t('common.fields.hours_minutes_value', { hours, minutes });
		}
		return t('common.fields.short_day');
	};

	/**
	 * Ex: du 13/02/2024 au 15/02/2024, le 17/02/2024 - 0h45, ...
	 */
	const getPrettyLeaveDaysHistoryPeriod = (history: LeaveDaysHistory): string => {
		if (history.isHalfDay) {
			if (history.halfDayOption) {
				return t(`common.fields.period.half_day.${history.halfDayOption}`, { date: getPrettyDate(history.start) });
			}
			return t('common.fields.period.half_day.not_specified', { date: getPrettyDate(history.start) });
		}
		if (history.isHours) {
			return t('common.fields.period.hours', {
				date: getPrettyDate(history.start),
				duration: getPrettyLeaveDaysHistoryDuration(history)
			});
		}

		const start = DateTime.fromISO(history.start);
		const end = DateTime.fromISO(history.end);
		const diffInDays = end.diff(start, 'days').days;
		if (diffInDays >= 1) {
			return t('common.fields.period.many_days', {
				dateStart: getPrettyDate(history.start),
				dateEnd: getPrettyDate(history.end)
			});
		}
		return t('common.fields.period.one_day', { date: getPrettyDate(history.start) });
	};

	const findLastInHistory = (history: LeaveDaysHistory[], state: LeaveDaysState): Optional<LeaveDaysHistory> => {
		return history
			.filter(h => h.status === state)
			.reduce<Optional<LeaveDaysHistory>>((acc, current) => {
				if (acc && DateTime.fromISO(acc.start) > DateTime.fromISO(current.start)) {
					return acc;
				}
				return current;
			}, undefined);
	};

	return {
		getPrettyDate,
		asPrettyLeaveDays,
		getPrettyLabel,
		getPrettyLeaveDaysPeriod,
		getPrettyLeaveDaysDuration,
		getPrettyLeaveDaysHistoryDuration,
		getPrettyLeaveDaysHistoryPeriod,
		findLastInHistory
	};
}
